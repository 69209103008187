// Contexts
import { useDeviceInformationContext } from "@/context/device-information/DeviceInformationContext";

// Node Modules
import {
  LogsEvent,
  datadogLogs
} from "@datadog/browser-logs";
import {

  // RumErrorEvent,
  RumInitConfiguration,
  datadogRum
} from "@datadog/browser-rum";
import {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

// Services
import { getGaUserSessionId } from "@/components/services/browser-storage/sessionStorageService";
import { postDataDogMetrics } from "@/services/dataDogMetricsService";
import {
  addKioskInformationToDatadogLog,
  removeSensitiveInformationFromPaymentLogs
} from "@/components/services/dataDogLoggingService";

// Scripts
import { getIsSoftwareVisible } from "@/scripts/softwareVisibilityUtil";

// Types
import IDataDogContext from "@/interfaces/context/data-dog/IDataDogContext";
import IDataDogContextProvider from "@/interfaces/context/data-dog/IDataDogContextProvider";

const releaseVersion = "1.0.0";
const dataDogService = "kiosk";
const dataDogSite = "datadoghq.com";
const dataDogEnvironment = process.env.NEXT_PUBLIC_VERCEL_ENV ?? "localhost";
const DataDogContext = createContext<IDataDogContext | undefined>(undefined);

const useDataDogContext = () => {
  const context = useContext(DataDogContext);
  if (context === undefined) {
    throw new Error("useDataDogContext must be used within a DataDogContextProvider");
  }
  return context;
};

const DataDogContextProvider = ({
  children,
}: IDataDogContextProvider) => {
  const {
    deviceInformation,
  } = useDeviceInformationContext();

  const [isDataDogMetricsAndLogsInitialized, setIsDataDogMetricsAndLogsInitialized] = useState<boolean>(false);
  const [isDataDogRumInitialized, setIsDataDogRumInitialized] = useState<boolean>(false);
  const [rumTriedStartingButDeviceInfoWasEmpty, setRumTriedStartingButDeviceInfoWasEmpty] = useState<boolean>(false);
  const submitMetricHeartBeatRef = useRef<NodeJS.Timeout>();

  const initializeDatadogLogs = (): void => {
    const gaUserSessionId: string = getGaUserSessionId();
    datadogLogs.init({
      beforeSend: (log: LogsEvent) => {
        log = addKioskInformationToDatadogLog(deviceInformation, log);
        removeSensitiveInformationFromPaymentLogs(log);

        return true;
      },
      clientToken: process.env.DATADOG_TOKEN,
      site: dataDogSite,
      env: dataDogEnvironment,
      forwardErrorsToLogs: true,
      service: dataDogService,
      version: releaseVersion,
    });
    datadogLogs.setUser({
      id: gaUserSessionId,
    });
  };

  const initializeDataDogMetrics = () => {
    setInterval(() => {
      getIsSoftwareVisible(deviceInformation);
    }, parseInt(process.env.DATADOG_HEARTBEAT_INTERVAL_MS))

    const heartBeatEnabled = process.env.DATADOG_HEARTBEAT_METRIC_ENABLED == "true";
    if (heartBeatEnabled) {
      submitMetricHeartBeatRef.current = !submitMetricHeartBeatRef.current && setInterval(
        () => {
          const {
            kioskId,
            kioskComputerName,
            storeNumber,
          } = deviceInformation;

          if (deviceInformation.kioskComputerName) {
            const host = `https://${window.location.host}`;
            postDataDogMetrics(kioskId, kioskComputerName, storeNumber, host, process.env.DATADOG_HEARTBEAT_METRIC_NAME);
          }
        }, parseInt(process.env.DATADOG_HEARTBEAT_INTERVAL_MS));
    }
  };

  const initializeDataDogRealUserMonitoring = (): void => {
    const deviceInfoHasData = deviceInformation.brand != undefined;

    if (deviceInfoHasData) {
      if (!isDataDogRumInitialized) {
        const initConfiguration: RumInitConfiguration = {
          allowedTracingUrls: [new RegExp("^https:\/\/kiosk-.*\.vercel.app\/api.*")], // nextjs api routes
          applicationId: process.env.DATADOG_RUM_APP_ID,

          // beforeSend: ((event: RumErrorEvent) => {
          //   if (event.type == "error" && event.view.url.includes("xl2Parameters=")) {
          //     const cleanedValues = cleanValue(event.view.url, openEdgeXmlRegex, cleanOpenEdgeXml)
          //     event.view.url = cleanedValues;
          //     event.error.stack = cleanedValues;
          //     event.error.resource.url = cleanValue(event.error.resource.url, openEdgeXmlRegex, cleanOpenEdgeXml);
          //   }

          //   return true;
          // }),
          clientToken: process.env.DATADOG_RUM_CLIENT_TOKEN,
          defaultPrivacyLevel: "mask-user-input",
          env: dataDogEnvironment,
          service: dataDogService,
          site: dataDogSite,
          sessionReplaySampleRate: 100,
          sessionSampleRate: 100,
          traceSampleRate: 100,
          trackLongTasks: true,
          trackResources: true,
          trackUserInteractions: true,
          version: releaseVersion,
        };

        datadogRum.setUser({
          name: deviceInformation.kioskComputerName,
          id: deviceInformation.storeNumber,
        });

        datadogRum.init(initConfiguration);

        setIsDataDogRumInitialized(true);
      }
    } else {
      setRumTriedStartingButDeviceInfoWasEmpty(true);
    }
  };

  const stopDataDogRealUserMonitoringSession = (): void => {
    datadogRum.stopSession();
  };

  useEffect(() => {
    const deviceInfoHasData = deviceInformation.brand != undefined;
    if (deviceInfoHasData) {
      if (!isDataDogMetricsAndLogsInitialized) {
        initializeDatadogLogs();
        initializeDataDogMetrics();
        setIsDataDogMetricsAndLogsInitialized(true);
      }

      // RUM gets started from <KioskApp>
      if (rumTriedStartingButDeviceInfoWasEmpty) {
        initializeDataDogRealUserMonitoring();
        setRumTriedStartingButDeviceInfoWasEmpty(false);
      }
    }
  }, [deviceInformation]);

  useEffect(() => {
    return () => {
      if (submitMetricHeartBeatRef.current) {
        clearInterval(submitMetricHeartBeatRef.current);
      }
    }
  }, []);

  const contextValues: IDataDogContext = {
    initializeDataDogRealUserMonitoring,
    stopDataDogRealUserMonitoringSession,
  };

  return (
    <DataDogContext.Provider
      value={contextValues}
    >
      {children}
    </DataDogContext.Provider>
  );
};

export {
  DataDogContext,
  DataDogContextProvider,
  useDataDogContext,
};
