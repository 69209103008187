// Components
import AudioCueHandler from "@/components/audio-cue/AudioCueHandler";
import CameraCustomerDetectionHandler from "@/components/customer-detection/CameraCustomerDetectionHandler";
import ProximitySensorHandler from "@/components/customer-detection/proximity-sensor/ProximitySensorHandler";

// Context
import { useCustomerDetectionContext } from "@/context/CustomerDetectionContext";
import { useCustomerSupportContext } from "@/context/CustomerSupportContext";
import { useDeviceInformationContext } from "@/context/device-information/DeviceInformationContext";
import { useKioskUserSessionContext } from "@/context/KioskUserSessionContext";

// Enum
import { CameraConfig } from "@/enums/optimizely/HardwareConfig/CameraConfig";
import { ProxSensorConfig } from "@/enums/optimizely/HardwareConfig/ProxSensorConfig";

// Node Modules
import { useDecision } from "@optimizely/react-sdk";
import {
  useCallback,
  useEffect,
  useState
} from "react";

// Scripts
import { dataDogLogMessages } from "@/scripts/constant-types/logging/dataDogLogMessages";
import dataLayerTypes from "@/scripts/constant-types/google-analytics/dataLayerTypes";
import {
  isCustomerDetectedByCamera,
  isCustomerDetectedByProxSensor,
  isCustomerEngagedByCamera,
  isCustomerEngagedByProxSensor,
  isCustomerNotPresentByCamera,
  isCustomerNotPresentByProxSensor,
  isItTimeToClearCurrentDetectionSession,
  registerCustomerDetectedByCameraEvent,
  registerCustomerDetectedByProxSensorEvent,
  registerCustomerDisengagedByCameraEvent,
  registerCustomerDisengagedByProxSensorEvent,
  registerCustomerEngagedByCameraEvent,
  registerCustomerEngagedByProxSensorEvent,
  registerCustomerInteractingWithKioskEvent
} from "@/scripts/customerDetectionHelper";

// Services
import dataLayerService from "@/services/dataLayerService";
import { postDataDogMetrics } from "@/services/dataDogMetricsService";

// Types
import ICameraReading from "@/interfaces/customer-detection/camera/ICameraReading";
import ICustomerDetectionEvent from "@/interfaces/customer-detection/ICustomerDetectionEvent";
import IProximitySensorReading from "@/interfaces/hardware-integration-module/proximity-sensor/IProximitySensorReading";

const CustomerDetectionHandler = (): JSX.Element => {
  const [cameraReadings, setCameraReadings] = useState<ICameraReading[]>([]);
  const [proximitySensorReadings, setProximitySensorReadings] = useState<IProximitySensorReading[]>([]);
  const [customerDetectionEvent, setCustomerDetectionEvent] = useState<ICustomerDetectionEvent | null>(null);
  const [isCameraEnabledForCustomerDetectionOnThisKiosk, setIsCameraEnabledForCustomerDetectionOnThisKiosk] = useState<boolean>(false);
  const [isCameraAccessibleFromThisBrowser, setIsCameraAccessibleFromThisBrowser] = useState<boolean>(false);
  const [isProxSensorEnabledForThisKiosk, setIsProxSensorEnabledForThisKiosk] = useState<boolean>(false);
  const [isProxSensorOnlineForThisKiosk, setIsProxSensorOnlineForThisKiosk] = useState<boolean>(false);
  const [shouldExecuteEngagedAction, setShouldExecuteEngagedAction] = useState<boolean>(false);
  const [mediaStream, setMediaStream] = useState<MediaStream | null>(null);
  const {
    clearCustomerDetectionEventRef,
    isCustomerInteractingWithTheScreen,
    proximitySensorThreshold,
    setIsCustomerInteractingWithTheScreen,
    setShouldPlayAudio,
  } = useCustomerDetectionContext();

  const {
    isInteractionCallFeatureEnabled,
    setHasInteractionCallBeenTriggeredForThisSession,
    startCallFunctionRef,
  } = useCustomerSupportContext();

  const {
    deviceInformation,
  } = useDeviceInformationContext();

  const {
    closeAttractionLoopRef,
    setTouchStartIsReadyCssClass,
    showAttractionLoop,
  } = useKioskUserSessionContext();

  const [cameraDecision] = useDecision(CameraConfig.Variation, {
    autoUpdate: true,
  });

  const [proxDecision] = useDecision(ProxSensorConfig.Variation, {
    autoUpdate: true,
  });

  const startInteractionCall = async (): Promise<void> => {
    if (startCallFunctionRef.current) {
      dataLayerService.pushEvent({
        event: dataLayerTypes.events.interactionCall,
      });

      await startCallFunctionRef.current();
    }
  };

  const clearCustomerDetectionEvent = useCallback((): void => {
    setCustomerDetectionEvent(null);
    setIsCustomerInteractingWithTheScreen(false);
    setShouldExecuteEngagedAction(false);
    setHasInteractionCallBeenTriggeredForThisSession(false);
    setShouldPlayAudio(false);
  }, []);

  useEffect(() => {
    clearCustomerDetectionEventRef.current = clearCustomerDetectionEvent;
  }, [clearCustomerDetectionEvent]);

  useEffect(() => {
    const hardwareConfigurationCheck = async (): Promise<void> => {
      const isCameraEnabledForCustomerDetectionOnThisKiosk = cameraDecision?.enabled && cameraDecision?.variationKey === CameraConfig.Variation;
      const isProxSensorEnabledForThisKiosk = proxDecision?.enabled && proxDecision?.variationKey === ProxSensorConfig.Variation;
      setIsProxSensorEnabledForThisKiosk(isProxSensorEnabledForThisKiosk)
      setIsCameraEnabledForCustomerDetectionOnThisKiosk(isCameraEnabledForCustomerDetectionOnThisKiosk)
    };
    hardwareConfigurationCheck();
  }, [cameraDecision, proxDecision]);

  useEffect(() => {
    const {
      kioskId,
      kioskComputerName,
      storeNumber,
    } = deviceInformation;

    const host = `https://${window.location.host}`;

    const touchStartEvent = (): void => {
      postDataDogMetrics(kioskId!, kioskComputerName!, storeNumber!, host, dataDogLogMessages.customerDetection.customerScreenTap);
    };

    window.addEventListener("touchstart", touchStartEvent);

    return () => window.removeEventListener("touchstart", touchStartEvent);
  }, []);

  useEffect(() => {
    if (!isInteractionCallFeatureEnabled) {
      setShouldExecuteEngagedAction(false);
    }

    if (shouldExecuteEngagedAction && isInteractionCallFeatureEnabled) {
      const isAttractionLoopOpen: boolean = showAttractionLoop;
      if (isAttractionLoopOpen && closeAttractionLoopRef.current) {
        closeAttractionLoopRef.current();
      }

      if (!showAttractionLoop) {
        setHasInteractionCallBeenTriggeredForThisSession(true);

        // The call will happen when the attraction loop is closed or if it was not on at all.
        // Without the if (!showAttractionLoop), the call would happen twice.
        // This setTimeout is necessary to ensure that the footer renders completely when the attraction loop is closed and
        // startCallFunctionRef.current holds the correct function reference.
        setTimeout(() => {
          startInteractionCall();
        }, 1000);
      }
    }
  }, [
    isInteractionCallFeatureEnabled,
    showAttractionLoop,
    shouldExecuteEngagedAction,
  ]);

  useEffect(() => {
    const isAtLeastOneSensorEnabled: boolean = isCameraEnabledForCustomerDetectionOnThisKiosk || isProxSensorEnabledForThisKiosk;
    let touchStartEvent: () => void;

    if (isAtLeastOneSensorEnabled) {
      touchStartEvent = () => setIsCustomerInteractingWithTheScreen(true);
      window.addEventListener("touchstart", touchStartEvent);
      setTouchStartIsReadyCssClass("customer-interacting-touch-start-ready");
    }

    return () => {
      if (isAtLeastOneSensorEnabled) {
        window.removeEventListener("touchstart", touchStartEvent);
      }
    }
  }, [isCameraEnabledForCustomerDetectionOnThisKiosk, isProxSensorEnabledForThisKiosk]);

  useEffect(() => {
    const isTimeToClearCurrentDetectionSession = isItTimeToClearCurrentDetectionSession(customerDetectionEvent, isCameraAccessibleFromThisBrowser, isProxSensorOnlineForThisKiosk);

    if (isTimeToClearCurrentDetectionSession) {
      clearCustomerDetectionEvent();
    }
  }, [customerDetectionEvent]);

  useEffect(() => {
    if (isCustomerDetectedByCamera(cameraReadings, customerDetectionEvent)) {
      registerCustomerDetectedByCameraEvent(deviceInformation, cameraReadings, customerDetectionEvent, setCustomerDetectionEvent);
      setShouldPlayAudio(true);
    }

    if (isCustomerEngagedByCamera(cameraReadings, customerDetectionEvent)) {
      registerCustomerEngagedByCameraEvent(customerDetectionEvent, cameraReadings, setCustomerDetectionEvent);
      setShouldExecuteEngagedAction(true);
    }

    if (isCustomerNotPresentByCamera(cameraReadings)) {
      const shouldTriggerDisengagedEvent: boolean = !!customerDetectionEvent?.session_id &&
        !!customerDetectionEvent?.camera_event.date_started
        && !customerDetectionEvent?.camera_event.date_ended;

      if (shouldTriggerDisengagedEvent) {
        registerCustomerDisengagedByCameraEvent(cameraReadings, customerDetectionEvent, setCustomerDetectionEvent);
      }

      setCameraReadings([]);
    }
  }, [cameraReadings]);

  useEffect(() => {
    const proxSensorCalibrationValue: number = proximitySensorThreshold?.averageCalibrationValue;
    if (proximitySensorReadings?.length > 0) {
      if (isCustomerDetectedByProxSensor(proximitySensorReadings, customerDetectionEvent, proxSensorCalibrationValue)) {
        registerCustomerDetectedByProxSensorEvent(deviceInformation, customerDetectionEvent, proximitySensorReadings, proxSensorCalibrationValue, setCustomerDetectionEvent);
        setShouldPlayAudio(true);
      }

      if (isCustomerEngagedByProxSensor(proximitySensorReadings, customerDetectionEvent, proxSensorCalibrationValue)) {
        registerCustomerEngagedByProxSensorEvent(customerDetectionEvent, proximitySensorReadings, setCustomerDetectionEvent);
        setShouldExecuteEngagedAction(true);
      }

      if (isCustomerNotPresentByProxSensor(proximitySensorReadings, proxSensorCalibrationValue)) {
        const shouldTriggerDisengagedEvent: boolean = !!customerDetectionEvent?.session_id &&
          !!customerDetectionEvent?.proximity_sensor_event.date_started
          && !customerDetectionEvent?.proximity_sensor_event.date_ended;

        if (shouldTriggerDisengagedEvent) {
          registerCustomerDisengagedByProxSensorEvent(customerDetectionEvent, proximitySensorReadings, setCustomerDetectionEvent);
        }

        setProximitySensorReadings([]);
      }
    }
  }, [proximitySensorReadings]);

  useEffect(() => {
    if (isCustomerInteractingWithTheScreen) {
      registerCustomerInteractingWithKioskEvent(
        deviceInformation,
        cameraReadings,
        customerDetectionEvent,
        proximitySensorReadings,
        setCustomerDetectionEvent);
      setShouldExecuteEngagedAction(true);
    }
  }, [isCustomerInteractingWithTheScreen]);

  return (
    <>
      {
        isProxSensorEnabledForThisKiosk && (
          <ProximitySensorHandler
            setIsProxSensorOnlineForThisKiosk={setIsProxSensorOnlineForThisKiosk}
            setProximitySensorReadings={setProximitySensorReadings}
          />
        )
      }
      {
        isCameraEnabledForCustomerDetectionOnThisKiosk && (
          <CameraCustomerDetectionHandler
            mediaStream={mediaStream}
            setCameraReadings={setCameraReadings}
            setIsCameraAccessibleFromThisBrowser={setIsCameraAccessibleFromThisBrowser}
            setMediaStream={setMediaStream}
          />
        )
      }
      <AudioCueHandler
        customerDetectionEvent={customerDetectionEvent}
      />
    </>
  )
}

export default CustomerDetectionHandler;
