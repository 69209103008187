// Node Modules
import React, {
  createContext,
  useContext,
  useMemo,
  useState,
} from "react";

// Types
import IErrorBoundaryContext from "@/interfaces/context/error-boundary/IErrorBoundaryContext";
import IErrorBoundaryContextProvider from "@/interfaces/context/error-boundary/IErrorBoundaryContextProvider";

const ErrorBoundaryContext = createContext<IErrorBoundaryContext | undefined>(undefined);

const useErrorBoundaryContext = (): IErrorBoundaryContext => {
  const context = useContext(ErrorBoundaryContext);
  if (!context) {
    throw new Error("useErrorBoundaryContext must be used within a ErrorBoundaryContextProvider");
  }

  return context;
};

const ErrorBoundaryContextProvider = ({
  children,
  value,
}: IErrorBoundaryContextProvider): JSX.Element => {
  const [hasError, setHasError] = useState(value?.hasError ?? false);
  const showErrorBoundary = (): void => setHasError(true);

  const errorBoundaryValue: IErrorBoundaryContext = useMemo(() => ({
    hasError,
    setHasError,
    showErrorBoundary,
  }), [hasError, setHasError]);

  return (
    <ErrorBoundaryContext.Provider
      value={errorBoundaryValue}
    >
      {children}
    </ErrorBoundaryContext.Provider>
  );
};

export {
  ErrorBoundaryContext,
  ErrorBoundaryContextProvider,
  useErrorBoundaryContext,
};
